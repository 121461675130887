import axios from "axios";
import urlApi from "@/services/url.api.js"
import authHeader from "@/services/auth-header";
import urltools from "./urltools";
const API_URL = urlApi.api_url;


class acapi {

    // Agregar un nuevo tipo de petición
    agregarTipoPeticion(data) {
        return axios.post(API_URL + "/api/TiposPeticiones/Agregar", data, {
            headers: authHeader(),
        });
    }

    // Actualizar un tipo de petición existente
    actualizarTipoPeticion(data) {
        return axios.put(API_URL + "/api/TiposPeticiones/Actualizar", data, {
            headers: authHeader(),
        });
    }

    // Eliminar un tipo de petición por su ID
    eliminarTipoPeticion(idTipoPeticion) {
        return axios.delete(API_URL + "/api/TiposPeticiones/Eliminar", {
            params: { idTipoPeticion: idTipoPeticion },
            headers: authHeader(),
        });
    }
    
    //Eliminar a un usuario
    eliminarUsuario(idUsuario) {
        var para = [
            { IdUsuario: idUsuario },
        ]
        var urlComplemento = urltools.construyeUrl("/api/Usuarios/EliminarUsuario", para);

        return axios.delete(API_URL + urlComplemento, {
            headers: authHeader(),
        });
    }
    //Leer los tipos de clasificaciones de las peticiones
    leerListaTiposPeticiones() {
        return axios.get(API_URL + "/api/TiposPeticiones/LeerLista", {
            headers: authHeader(),
        });
    }

    subirImagenParaIdentificacion(data) {
        return axios.post(API_URL + "/WeatherForecast/processImage", data, {
            headers: authHeader(),
        });
    }

    //Leer un archivo
    leerArchivo(claveArchivo) {
        var para = [
            { ClaveArchivo: claveArchivo },
        ]
        var urlComplemento = urltools.construyeUrl("/api/Imagenes/LeerArchivo", para);

        return axios.get(API_URL + urlComplemento, {
            headers: authHeader(),
            responseType: 'blob'
        });
    }
    //Leer los archivos de evidencia 
    leerArchivosEvidenciaFinPeticion(clavePeticion) {
        var para = [
            { ClavePeticion: clavePeticion },
        ]
        var urlComplemento = urltools.construyeUrl("/api/Peticiones/LeerArchivosEvidenciaFin", para);

        return axios.get(API_URL + urlComplemento, {
            headers: authHeader(),
        });
    }
    //Borrar un perfil de un usuario
    borrarPerfilUsuario(idUsuario, idPerfil) {
        var para = [
            { IdUsuario: idUsuario },
            { IdPerfil: idPerfil },
        ]
        var urlComplemento = urltools.construyeUrl("/api/Usuarios/BorrarPerfilUsuario", para);

        return axios.delete(API_URL + urlComplemento, {
            headers: authHeader(),
        });
    }

    //Agregar un perfil al usuario
    agregarPerfilAUsuario(data) {
        return axios.post(API_URL + "/api/Usuarios/AgregarPerfilAUsuario", data, {
            headers: authHeader(),
        });
    }

    //Leer los perfiles asociados a un usuario
    leerPerfilesUsuario(idUsuario) {
        var para = [
            { IdUsuario: idUsuario },
        ]
        var urlComplemento = urltools.construyeUrl("/api/Usuarios/LeerPerfilesUsuario", para);

        return axios.get(API_URL + urlComplemento, {
            headers: authHeader(),
        });
    }

    //Leer los perfiles disponibles para la organización
    leerPerfiles() {
        return axios.get(API_URL + "/api/Perfiles/LeerLista", {
            headers: authHeader(),
        });
    }

    //LEER LAS ACCIONES DE GOBIERNO REGISTRADAS
    leerAccionesDeGobiernoRegistradas() {
        return axios.get(API_URL + "/api/AccionesGobiernoTerritorio/LeerAccionesDeGobierno", {
            headers: authHeader(),
        });
    }


    //AGREGAR UN REGISTRO DE ACCIONES DE GOBIERNO
    registraAccionGobiernoTerritorio(data) {
        return axios.post(API_URL + "/api/AccionesGobiernoTerritorio/registraAccionGobiernoTerritorio", data, {
            headers: authHeader(),
        });
    }


    //LEER IMAGENES IDENTIFICACIÓN ASPECTO
    leerImagenesIdentificacionAspecto(claveIdentificacionAspecto) {
        var para = [
            { ClaveIdentificacionAspecto: claveIdentificacionAspecto },
        ]
        var urlComplemento = urltools.construyeUrl("/api/IdentificaAspectos/LeerImagenesIdentificacionAspecto", para);

        return axios.get(API_URL + urlComplemento, {
            headers: authHeader(),
        });
    }


    //SUBIR IMAGEN DE EVIDENCIA DE UNA PROBLEMÁTICA
    subirImagenEvidenciaProblematica(data) {
        return axios.post(API_URL + "/api/IdentificaAspectos/SubirImagenEvidencia", data, {
            headers: authHeader(),
        });
    }

    //ACTUALIZAR UN NODO ACCIONES DE GOBIERNO
    actualizarNodoClasificacionAccionGobierno(data) {
        return axios.put(API_URL + "/api/ClasificacionAccionesGobierno/ActualizarNodo", data, {
            headers: authHeader(),
        });
    }

    //ELIMINAR NODO ACCIONES DE GOBIERNO
    eliminarNodoClasificacionAccionGobierno(claveClasificacionAccionGobierno) {
        var para = [
            { claveClasificacionAccionGobierno: claveClasificacionAccionGobierno },
        ]
        var urlComplemento = urltools.construyeUrl("/api/ClasificacionAccionesGobierno/EliminarNodo", para);

        return axios.delete(API_URL + urlComplemento, {
            headers: authHeader(),
        });
    }

    //ACCIONES DE GOBIERNO
    leerArbolAccionesGobierno() {
        return axios.get(API_URL + "/api/ClasificacionAccionesGobierno/GetArbol", {
            headers: authHeader(),
        });
    }

    //AGREGAR UN NODO ACCIONES DE GOBIERNO
    agregarNodoClasificacionAccionGobierno(data) {
        return axios.post(API_URL + "/api/ClasificacionAccionesGobierno/AgregarNodo", data, {
            headers: authHeader(),
        });
    }

    //Leer una problemática o aspecto identificado
    leerIdentificacionAspecto(claveIdentificacionAspecto) {
        var para = [
            { ClaveIdentificacionAspecto: claveIdentificacionAspecto },
        ]
        var urlComplemento = urltools.construyeUrl("/api/IdentificaAspectos/LeerIdentificacionAspecto", para);

        return axios.get(API_URL + urlComplemento, {
            headers: authHeader(),
        });
    }

    //Lista de usuarios activos
    getUsuariosActivos() {
        return axios.get(API_URL + "/api/Usuarios/getUsuariosActivos", {
            headers: authHeader(),
        });
    }

    //Leer un usuario
    actualizarUsuario(data) {
        return axios.put(API_URL + "/api/usuarios/ActualizarUsuario", data, {
            headers: authHeader(),
        });
    }

    //Leer un usuario
    leerUsuario(idUsuario) {
        var para = [
            { idUsuario: idUsuario },
        ]
        return axios.get(API_URL + urltools.construyeUrl("/api/usuarios/GetUsuario", para), {
            headers: authHeader(),
        });
    }

    //LIDERAZGOS
    //Leer todas las LIDERAZGOS
    leerNaturalezaLiderazgo() {
        return axios.get(API_URL + "/api/LiderazgosTipos/LeerLista", {
            headers: authHeader(),
        });
    }

    //Eliminar una LIDERAZGOS
    eliminarNaturalezaLiderazgo(idNaturalezaLiderazgo) {
        var para = [
            { idNaturalezaLiderazgo: idNaturalezaLiderazgo },
        ]
        return axios.delete(API_URL + urltools.construyeUrl("/api/LiderazgosTipos/Eliminar", para), {
            headers: authHeader(),
        });
    }

    //Editar una LIDERAZGOS
    actualizarNaturalezaLiderazgo(tiponatLider) {
        return axios.put(API_URL + "/api/LiderazgosTipos/Actualizar", tiponatLider, {
            headers: authHeader(),
        });
    }

    //Agregar una LIDERAZGOS
    agregarNaturalezaLiderazgo(tiponatLider) {
        return axios.post(API_URL + "/api/LiderazgosTipos/Agregar", tiponatLider, {
            headers: authHeader(),
        });
    }




    //APOYOS
    //Leer todas las APOYOS
    leerTiposApoyos() {
        return axios.get(API_URL + "/api/ApoyosTipos/LeerLista", {
            headers: authHeader(),
        });
    }

    //Eliminar una APOYOS
    eliminarTipoApoyo(idTipoApoyo) {
        var para = [
            { idTipoApoyo: idTipoApoyo },
        ]
        return axios.delete(API_URL + urltools.construyeUrl("/api/ApoyosTipos/Eliminar", para), {
            headers: authHeader(),
        });
    }

    //Editar una APOYOS
    actualizarTipoApoyo(tipoApoyo) {
        return axios.put(API_URL + "/api/ApoyosTipos/Actualizar", tipoApoyo, {
            headers: authHeader(),
        });
    }

    //Agregar una APOYOS
    agregarTipoApoyo(tipoApoyo) {
        return axios.post(API_URL + "/api/ApoyosTipos/Agregar", tipoApoyo, {
            headers: authHeader(),
        });
    }





    //Leer todas las ocupaciones
    leerOcupaciones() {
        return axios.get(API_URL + "/api/Ocupaciones/LeerLista", {
            headers: authHeader(),
        });
    }

    //Eliminar una ocupación
    eliminarOcupacion(idOcupacion) {
        var para = [
            { idOcupacion: idOcupacion },
        ]
        return axios.delete(API_URL + urltools.construyeUrl("/api/Ocupaciones/Eliminar", para), {
            headers: authHeader(),
        });
    }

    //Editar una ocupación
    actualizarOcupacion(ocupacion) {
        return axios.put(API_URL + "/api/Ocupaciones/Actualizar", ocupacion, {
            headers: authHeader(),
        });
    }

    //Agregar una ocupación
    agregarOcupacion(ocupacion) {
        return axios.post(API_URL + "/api/Ocupaciones/Agregar", ocupacion, {
            headers: authHeader(),
        });
    }


    //Eliminar un registro de clasificación problemática
    eliminarClasificacionProblematica(IdClasificacionProblematica) {
        var para = [
            { IdClasificacionProblematica: IdClasificacionProblematica },
        ]
        var param = urltools.convierte(para);
        return axios.delete(API_URL + "/api/ClasificacionProblematica/eliminarClasificacionProblematica?" + param, {
            headers: authHeader(),
        });
    }
    //Menú del usuario
    getMenuUsuario() {
        return axios.get(API_URL + "/api/Usuarios/getMenuUsuario", {
            headers: authHeader(),
        });
    }
    //Leer la lista de usuarios registrados para usar el sistema
    getListaUsuarios() {
        return axios.get(API_URL + "/api/Usuarios/getUsuarios", {
            headers: authHeader(),
        });
    }
    //Eliminar un nodo de clasificación territorial
    eliminarNodoClasificacionTerritorial(claveClasificacionTerritorial) {
        var para = [
            { ClaveClasificacionTerritorial: claveClasificacionTerritorial },
        ]

        var param = urltools.convierte(para);

        return axios.delete(API_URL + "/api/ClasificacionTerritorial/eliminarNodoClasificacionTerritorial?" + param, {
            headers: authHeader(),
        });
    }

    //Eliminar un evento
    eliminarEvento(claveEvento) {
        return axios.delete(API_URL + "/api/Eventos/EliminarEvento/" + claveEvento, {
            headers: authHeader(),
        });
    }
    //Registrar un apoyo o algunos otros valores como liderazgo, colonos, etc
    registraPersonaYAtributos(data) {
        return axios.post(API_URL + "/api/ApoyosPersonas/RegistraPersonaYAtributos", data, {
            headers: authHeader(),
        });
    }
    //Obtener el tablero de las causas raices, contiene los territorios donde se presenta algún elemento jijo
    //de la causa raiz
    getProblematicasRaizConDetalleTerritorio() {
        return axios.get(API_URL + "/api/IdentificaAspectos/GetProblematicasRaizConDetalleTerritorio", {
            headers: authHeader(),
        });
    }

    //Obtener todos los aspectos (semáforos) de un territoriro
    getAspectosDeTerritorioConHistorial(claveClasificacionTerritorial) {
        return axios.get(API_URL + "/api/IdentificaAspectos/GetAspectosDeTerritorioActualHist?claveClasificacionTerritorial=" + claveClasificacionTerritorial, {
            headers: authHeader(),
        });
    }

    //Obtener todos los aspectos (semáforos) de un territoriro
    getAspectosDeTerritorio(claveClasificacionTerritorial) {
        return axios.get(API_URL + "/api/IdentificaAspectos/GetAspectosDeTerritorio?claveClasificacionTerritorial=" + claveClasificacionTerritorial, {
            headers: authHeader(),
        });
    }

    //Historial de una petición, este método es público
    leerHistorialUsuarioFinal(clavePeticion) {
        return axios.get(API_URL + "/api/Peticiones/leerPeticionParaUsuarioFinal/" + clavePeticion);
    }

    //Archivar una petición
    archivarPeticion(clavePeticion) {
        return axios.get(API_URL + "/api/Peticiones/ArchivarPeticion?clavePeticion=" + clavePeticion, {
            headers: authHeader(),
        });
    }
    //Eliminar un nodo de la estructura de la organización
    eliminarElementoEstructuraOrganizacion(claveOrganizacionEstructura) {
        return axios.delete(API_URL + "/api/Organizacion/eliminarElementoEstructuraOrganizacion/" + claveOrganizacionEstructura, {
            headers: authHeader(),
        });
    }
    //Leer los atributos más actuales de una clasificación territorial
    getValoresAtributosTerritorio(claveClasificacionTerritorial) {
        return axios.get(API_URL + "/api/ClasificacionTerritorial/getValoresAtributosTerritorio/" + claveClasificacionTerritorial, {
            headers: authHeader(),
        });
    }
    //Agregar un atributo a una clasificación territoriral
    agregarAtributoClasificacionTerritorial(data) {
        return axios.post(API_URL + "/api/ClasificacionTerritorial/AgregarAtributoClasificacionTerritorial", data, {
            headers: authHeader(),
        });
    }
    //Leer los atributos de clasificación territorial
    leerAtributosClasificacionTerritorial() {
        return axios.get(API_URL + "/api/ClasificacionTerritorial/LeerAtributosClasificacionTerritorial", {
            headers: authHeader(),
        });
    }
    //Leer el comité de colonos
    leerComiteColonos(claveClasificacionTerritorial) {
        return axios.get(API_URL + "/api/Personas/LeerComiteColonos/" + claveClasificacionTerritorial, {
            headers: authHeader(),
        });
    }

    //Agregar a una persona
    agregarPersona(data) {
        return axios.post(API_URL + "/api/Personas/AgregarPersona", data, {
            headers: authHeader(),
        });
    }

    //finaliza o resuelve una identificacion de un aspecto
    resuelveAspectoIdentificado(data) {
        console.log(data);
        return axios.post(API_URL + "/api/IdentificaAspectos/ResuelveAspectoIdentificado/", data, {
            headers: authHeader(),
        });
    }
    //identificacion de un aspecto
    agregarIdentificacionAspecto(data) {
        console.log(data);
        return axios.post(API_URL + "/api/IdentificaAspectos/AgregarIdentificacion/", data, {
            headers: authHeader(),
        });
    }
    //Clasificación territorial
    //Agregar una clasificación de problemática
    agregarClasificacionTerritorial(data) {
        return axios.post(API_URL + "/api/ClasificacionTerritorial/AgregarClasificacionTerritorial/", data, {
            headers: authHeader(),
        });
    }
    //Actualizar una clasificación de problemática
    actualizarClasificacionTerritorial(data) {
        return axios.put(API_URL + "/api/ClasificacionTerritorial/ActualizarClasificacionTerritorial/", data, {
            headers: authHeader(),
        });
    }
    //Leer el arbol de clasificaciones de problematicas
    leerArbolClasificacionTerritorial() {
        return axios.get(API_URL + "/api/ClasificacionTerritorial/LeerArbol/", {
            headers: authHeader(),
        });
    }
    //Fin clasificación territoriral

    //Agregar una clasificación de problemática
    agregarClasificacionProblematica(data) {
        return axios.post(API_URL + "/api/ClasificacionProblematica/AgregarClasificacionProblematica/", data, {
            headers: authHeader(),
        });
    }
    //Actualizar una clasificación de problemática
    actualizarClasificacionProblematica(data) {
        return axios.put(API_URL + "/api/ClasificacionProblematica/ActualizarClasificacionProblematica/", data, {
            headers: authHeader(),
        });
    }
    //Leer el arbol de clasificaciones de problematicas
    leerArbolClasificacionProblematica() {
        return axios.get(API_URL + "/api/ClasificacionProblematica/LeerArbol/", {
            headers: authHeader(),
        });
    }
    //Ver las peticiones en las que está involucrado un usuario
    leerPeticionesPuedeVerUsuario(PaginaActual, NumeroRegistros) {
        return axios.get(API_URL + "/api/Peticiones/LeerPeticionesPuedeVerUsuario/" + PaginaActual + "/" + NumeroRegistros, {
            headers: authHeader(),
        });
    }
    //Fnializar una petición
    finalizarPeticion(data, archivos) {
        const formData = new FormData();
        formData.append("dataJson", JSON.stringify(data));

        // Agregar archivos al FormData
        /*
        archivos.forEach((file, index) => {
            var nombre = "archivos[" + index + "]"
            formData.append(nombre, file);
        });
        */

        archivos.forEach((file) => {
            formData.append("archivos", file); // Usa "archivos" sin índices
        });

        return axios.post(API_URL + "/api/Peticiones/FinalizarPeticion", formData, {
            headers: {
                ...authHeader(),
                'Content-Type': 'multipart/form-data', // Agrega este encabezado
            }
        });
    }
    //Eliminar un acceso de un usuario a un evento
    eliminarAccesoUsuarioEvento(ClaveEventoAccesoUsuario) {
        return axios.delete(API_URL + "/api/Eventos/EliminarAccesoUsuarioEvento/" + ClaveEventoAccesoUsuario, {
            headers: authHeader(),
        });
    }
    //Leer los usuarios que tienen acceso al evento
    leerUsuariosConAccesoAEvento(claveEvento) {
        return axios.get(API_URL + "/api/Eventos/LeerUsuariosConAccesoAEvento/" + claveEvento, {
            headers: authHeader(),
        });
    }
    //Dar permiso a un usuario a un evento
    darAccesoUsuarioAEventoUsandoNombreDeUsuario(data) {
        return axios.post(API_URL + "/api/Eventos/DarAccesoUsuarioAEventoUsandoNombreDeUsuario", data, {
            headers: authHeader(),
        });
    }
    // Turnar una petición
    turnarPeticion(data) {
        return axios.post(API_URL + "/api/Peticiones/TurnarPeticion", data, {
            headers: authHeader(),
        });
    }
    // Leer seguimientos de un turno
    leerSeguimietosTurno(ClavePeticionTurno) {
        return axios.get(API_URL + "/api/Peticiones/LeerSeguimietosTurno/" + ClavePeticionTurno, {
            headers: authHeader(),
        });
    }

    // Agregar un seguimiento a un turno
    agregarSeguimientoTurno(data) {
        return axios.post(API_URL + "/api/Peticiones/AgregarSeguimientoTurno", data, {
            headers: authHeader(),
        });
    }
    //Leer los turnos de una petición
    leerTurnosPeticion(clavePeticion) {
        return axios.get(API_URL + "/api/Peticiones/LeerTurnosPeticion/" + clavePeticion, {
            headers: authHeader(),
        });
    }
    //Leer una peticion
    leerPeticion(clavePeticion) {
        return axios.get(API_URL + "/api/Peticiones/LeerPeticion/" + clavePeticion, {
            headers: authHeader(),
        });
    }
    //Leer un evento
    leerEvento(claveEvento) {
        return axios.get(API_URL + "/api/Eventos/LeerEvento/" + claveEvento, {
            headers: authHeader(),
        });
    }
    //Crear un evento
    crearEvento(data) {
        return axios.post(API_URL + "/api/Operacion/CrearEvento", data, {
            headers: authHeader(),
        });
    }
    //Leer los usuarios asociados a una estructura de la organización
    leerUsuariosAsociadosEstructura(ClaveOrganizacionEstructura) {
        return axios.get(API_URL + "/api/Operacion/LeerUsuariosAsociadosEstructura/" + ClaveOrganizacionEstructura, {
            headers: authHeader(),
        });
    }
    //Asociar un usuario con un elemento de la estructura de la organización
    asociarUsuarioConEstructuraOrganizacion(Data) {
        return axios.post(API_URL + "/api/Operacion/AsociarUsuarioConEstructuraOrganizacion", Data, {
            headers: authHeader(),
        });
    }
    //Registrar a un usuario
    registraUsuario(Data) {
        return axios.post(API_URL + "/api/Usuarios/RegistraUsuario", Data, {
            headers: authHeader(),
        });
    }
    //Actualizar un elemento de la estructura de la organización
    actualizarElementoEstructura(OrganizacionEstructura) {
        return axios.put(API_URL + "/api/Operacion/ActualizarElementoEstructura", OrganizacionEstructura, {
            headers: authHeader(),
        });
    }
    //Agregar un elemento a la estructura de la organización
    agregarElementoEstructura(OrganizacionEstructura) {
        return axios.post(API_URL + "/api/Operacion/AgregarElementoEstructura", OrganizacionEstructura, {
            headers: authHeader(),
        });
    }
    //Leer el arbol de la estructura de la organziación
    leerArbolEstructuraOrganizacion() {
        return axios.get(API_URL + "/api/Operacion/LeerArbolEstructuraOrganizacion/", {
            headers: authHeader(),
        });
    }
    //Agregar una petición y asociarla a un evento
    agregarPeticionEvento(Data) {
        return axios.post(API_URL + "/api/Peticiones/AgregarPeticionEvento", Data, {
            headers: authHeader(),
        });
    }
    //Obtener los eventos a los que tiene acceso el usuario
    leerEventosUsuario(Anio, Mes) {
        return axios.get(API_URL + "/api/Eventos/LeerEventosUsuario/" + Anio + "/" + Mes, {
            headers: authHeader(),
        });
    }
    //Obtener las peticiones asociadas a un evento
    leerPeticionesEvento(ClaveEvento) {
        return axios.get(API_URL + "/api/Operacion/LeerPeticionesEvento/" + ClaveEvento, {
            headers: authHeader(),
        });
    }
    login(data) {
        return axios.post(API_URL + "/api/InicioSesion/IniciaSesion", data);
    }
    //Obtener las organizaciones del usuario
    leerMisOrganizaciones() {
        return axios.get(API_URL + "/api/Operacion/GetOrganizacionesUsuario", {
            headers: authHeader(),
        });
    }

}

export default new acapi();

