import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
//import './registerServiceWorker'
import store from './store'
import moment from 'moment'
import VueJwtDecode from 'vue-jwt-decode'
import VueApexCharts from 'vue-apexcharts'
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.config.productionTip = false
Vue.use(VueJwtDecode)
Vue.use(VueApexCharts)
Vue.component('apexChart', VueApexCharts)


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB4-JLBFcJxL7YpPB51Yz65XRJk5ZlIxs0',
    libraries: 'places', // Necesario si usas servicios de lugares
  }
});

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY');
  }
});
Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:MM');
  }
});